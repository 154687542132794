<template>
  <div>
    <div class="topLogo">
      <div class="topText">SERVICES</div>
      <!-- <div class="servcicesItemSmegma"></div> -->
      <img src="../img/serviceTop.jpg" alt />
    </div>
    <div
      v-for="(item,index) in servicesList"
      :key="index"
      class="servicesWrapper"
      :class="(index + 1) % 2==0?'evenWrapper':''"
      :ref="item.ref"
      :id="item.ref"
    >
      <div class="servicesLeft">
        <div class="title">{{ item.title }}</div>
        <div class="subTitle">{{ item.subTitle.toUpperCase() }}</div>
        <div class="des">{{ item.des }}</div>
      </div>
      <div class="servicesRight">
        <div class="imgBg"></div>
        <img class="img" :src="item.imgUrl" alt />
      </div>
    </div>
  </div>
</template>

<script>
import sales from "@/img/services/sales.png";
import maintenance from "@/img/services/maintenance.png";

import directional from "@/img/services/directional.png";

import fishing from "@/img/services/fishing.png";

export default {
  data() {
    return {
      servicesList: [
        {
          title: "SALES&RENTAL",
          ref: "sales",
          subTitle:
            "WELLDEBON PROVIDES A QUICK WAY TO QUICKLY OBTAIN ADVANCED DRILLING TOOLS AND LEASED EQUIPMENT",
          des:
            "With years of drilling tools sale and rental experience, we have a group of professional research, design, analysis and manufacture high quality drilling tools product that service and release to our customer.",
          imgUrl: sales
        },
        {
          title: "MAINTENANCE&INSPECTION",
          ref: "maintenance",
          subTitle: "Decades years of drilling tool production experience",
          des:
            "With decades years of drilling tools production experience, we have a team of professional quality inspector which conduct quality control and testing for products throughout the processes and ensure that only the product that fulfill quality requirement release to customer.",
          imgUrl: maintenance
        },
        {
          title: "DIRECTIONAL DRILLING SERVICES",
          ref: "directional",
          subTitle:
            "Leading technology and database to optimize wellbore layout and maximize efficiency",
          des:
            "Optimizing WELLDEBON placement and maximizing efficiency with leading technology and database, involves reducing geologic uncertainty and improving formation evaluation.",
          imgUrl: directional
        },
        {
          title: "FISHING SERVICES",
          ref: "fishing",
          subTitle:
            "Minimizing non production time（NPT）and fishing costs is our main task",
          des:
            "Stray or damaged equipment that falls or breaks inside a WELLDEBON-called fish or junk-must be retrieved by a fishing operation using specialized tools.Inefficient fishing operations may take days. Minimize your nonproductive time(NPT)and fishing costs is our main task.",
          imgUrl: fishing
        }
      ]
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path && to.query && to.query.ref) {
        window.scrollTo({
          top: document.getElementById(this.$route.query.ref).offsetTop,

          behavior: "smooth"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.topText {
  font-size: 80px;

  font-weight: 500;
  color: #ffffff;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topLogo {
  width: 100vw;
  height: 331px;
  background: #17457c;
  margin-bottom: 103px;
  position: relative;
}
.servcicesItemSmegma {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #17457c;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}
.servicesWrapper {
  display: flex;
  padding: 140px;
  .servicesLeft {
    width: 813px;
    color: #000;
    text-align: justify;
    margin-right: 170px;
    margin-left: 0;
    padding-top: 80px;
    .title {
      font-size: 48px;
      font-weight: 600;
      text-align: left;
      color: #000000;
    }
    .subTitle::before {
      width: 106px;
      height: 8px;
      background: #ffb81d;
      content: "";
      display: block;
      margin-top: 30px;
      margin-bottom: 18px;
    }
    .subTitle {
      font-size: 32px;
      font-weight: 600;
      color: #2e3192;
      margin-bottom: 60px;
      line-height: 50px;
    }
    .des {
      font-size: 30px;
      font-weight: 400;
      line-height: 50px;
    }
  }

  .servicesRight {
    flex: 1;
    position: relative;
    height: 717px;
    .imgBg {
      background: #2e3192;
      width: 647px;
      height: 717px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .img {
      width: 553px;
      height: 613px;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -60px;
    }
  }
}
.evenWrapper {
  flex-direction: row-reverse;
  .servicesLeft {
    margin-left: 170px;
    margin-right: 0;
  }
  .servicesRight {
    flex: 1;
    position: relative;
    height: 717px;
    .imgBg {
      background: rgba(0, 0, 0, 0.5);
      width: 647px;
      height: 717px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .img {
      width: 553px;
      height: 613px;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 164px;
    }
  }
}
</style>