<template>
  <div class="footComponentsWrapper">
    <div class="left">
      <div class="leftTitle">ABOUT US</div>
      <div
        v-for="(item, index) in routerArr"
        :key="index"
        :class="active == item.indexId ? 'active' : ''"
        class="leftItem"
        @click="leftClick(item,'/')"
      >
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div class="middle">
      <div class="middleTitle">PRODUCTS</div>

      <div
        v-for="(item, index) in businessesArr"
        :class="active == item.indexId ? 'active' : ''"
        :key="index"
        class="middleItem"
        @click="leftClick(item)"
      >
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div class="middle">
      <div class="middleTitle">SERVICES</div>

      <div
        v-for="(item, index) in servicesArr"
        :class="active == item.indexId ? 'active' : ''"
        :key="index"
        class="middleItem"
        @click="leftClick(item,'services')"
      >
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div class="desWrapper">
      <div class="desWrapperTitle">Beijing WellDebon Technology Ltd.</div>
      <div class="desContent">
        <div>
          <div class="desContentLeft">E-mail：</div>
          <div class="desContentRight">sales@welldebon.com</div>
        </div>
        <div>
          <div class="desContentLeft">tel：</div>
          <div class="desContentRight">+86-01053680377</div>
        </div>
        <div>
          <div class="desContentLeft">Website：</div>
          <div class="desContentRight">www.welldebon.com</div>
        </div>
        <div>
          <div class="desContentLeft">Address：</div>
          <div class="desContentRight">
            No. 18, Construction Road, Kaixuan Street, Liangshan District, Fangshan
            District, Beijing -
            D19596
          </div>
        </div>
      </div>
    </div>
    <div class="newlogoImg">
      <img src="../img/footLogo/f.png" @click="clickLogoF" class="footlogo" />
      <img src="../img/footLogo/t.png" @click="clickLogoT" class="footlogo" />
      <img src="../img/footLogo/in.png" @click="clickLogoIn" class="footlogo" />
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      routerArr: [
        {
          title: "Company profile",
          path: "/home?ref=profile",
          indexId: 0
        },
        {
          title: "Corporate culture",
          path: "/home?ref=culture",
          indexId: 1
        },
        {
          title: "News Center",
          path: "/home?ref=newsCenter",
          indexId: 2
        }
      ],
      businessesArr: [
        {
          title: "Downhole Motor",
          path: "/products?type=3",
          indexId: 4,
          type: 3
        },
        {
          title: "Drilling Jars",
          path: "/products?type=2",
          indexId: 5,
          type: 2
        },
        {
          title: "Hydraulic Oscollator",
          path: "/products?type=1",
          indexId: 6,
          type: 1
        },
        {
          title: "Other Drilling Tools",
          path: "/products?type=4",
          indexId: 7,
          type: 4
        },
        {
          title: "PDC Bit & Tricone Bit",
          path: "/products?type=7",
          indexId: 8,
          type: 7
        },
        {
          title: "Fishing Tools",
          path: "/products?type=5",
          indexId: 9,
          type: 5
        },
        {
          title: "Equipment of Maintenance",
          path: "/products?type=8",
          indexId: 10,
          type: 8
        }
      ],
      servicesArr: [
        {
          title: "Sales&Rental",
          path: "/services?ref=sales",
          indexId: 11
        },
        {
          title: "Maintenance&Inspection",
          path: "/services?ref=maintenance",
          indexId: 12
        },
        {
          title: "Directional Drilling Services",
          path: "/services?ref=directional",
          indexId: 13
        },
        {
          title: "Fishing Services",
          path: "/services?ref=fishing",
          indexId: 14
        }
      ],
      descArr: [],
      active: -1,
      hasActive: false
    };
  },
  created() {
    this.detectionAction(this.$route);
  },
  watch: {
    $route(to, from) {
      this.detectionAction(to);
    }
  },
  methods: {
    detectionAction(route) {
      if (route.query && route.query.type) {
        switch (route.query.type) {
          case "3":
            this.active = 4;
            break;
          case "2":
            this.active = 5;
            break;
          case "1":
            this.active = 6;
            break;
          case "4":
            this.active = 7;
            break;
          case "7":
            this.active = 8;
            break;
          case "5":
            this.active = 9;
            break;
          case "8":
            this.active = 10;
            break;
          default:
            this.active = -1;
            break;
        }
      }
      if (route.query && route.query.ref) {
        switch (route.query.ref) {
          case "profile":
            this.active = 0;
            break;
          case "culture":
            this.active = 1;
            break;
          case "newsCenter":
            this.active = 2;
            break;
          case "sales":
            this.active = 11;
            break;
          case "maintenance":
            this.active = 12;
            break;
          case "directional":
            this.active = 13;
            break;
          case "fishing":
            this.active = 14;
            break;
          default:
            this.active = -1;
            break;
        }
      }
      if ((route.path == "/" || route.path == "/home") && !route.query.ref) {
        this.active = 0;
        return;
      }
      if (route.path == "/services" && !route.query.ref) {
        this.active = 11;
        return;
      }
    },
    leftClick(item, path) {
      this.$emit("leftClick", item, path);
    },
    clickLogoF() {
      window.open("https://www.facebook.com/");
    },
    clickLogoT() {
      window.open("https://twitter.com/i/flow/login");
    },
    clickLogoIn() {
      window.open(
        "https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Ffeed%2F&fromSignIn=true&trk=cold_join_sign_in"
      );
    }
  }
};
</script>

<style lang="less" scoped>
.leftTitle,
.middleTitle {
  font-size: 24px;

  font-weight: 500;
  color: #fff;
  margin-bottom: 11px;
}

.left,
.middle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 110px;
}

.leftItem,
.middleItem {
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  margin: 10px 0;
  font-weight: 400;
}
.leftItem:hover,
.middleItem:hover {
  color: #ffb81d;
}
.footComponentsWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.desWrapper {
  // height: 160px;
  font-size: 19px;
  flex: 1;
  font-weight: 400;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.desContent {
  line-height: 27px;
  margin-top: 30px;
}

.desWrapperTitle {
  font-size: 29px;

  color: #fff;
}

.desContentLeft {
  display: inline-block;
  width: 5em;
  color: #fff;
}

.desContentRight {
  display: inline-block;
  color: #fff;
}

.newlogoImg {
  width: 230px;
  height: 58px;
  position: absolute;
  bottom: 0px;
  right: 0;
  .footlogo {
    width: calc(220px / 3);
    height: 58px;
    cursor: pointer;
  }
}
.active {
  color: #ffb81d;
}
</style>