<template>
  <div>
    <div class="topLogo">
      <div class="joinUs">JOIN US</div>
      <div class="servcicesItemSmegma"></div>

      <img src="../img/cooperate.png" alt />
    </div>
    <div class="careersWrapper" v-for="(item, index) in careersArr" :key="index">
      <div class="careersTitleWrapper">
        <div class="careersTitle">{{ item.title }}</div>
        <div class="iconWrapper">
          <div class="persionWrapper">
            <div class="imgWrapper">
              <img src="../img/persion.svg" alt class="persionIcon" />
            </div>
            {{ item.peopleNum }}
          </div>
          <div class="persionWrapper">
            <div class="imgWrapper">
              <img src="../img/local.svg" alt class="localIcon" />
            </div>
            {{ item.loacl }}
          </div>
        </div>
      </div>
      <div class="careersDesWrapper">
        <div class="careersDescription">Job Description</div>
        <br />
        <div class="careersDesc" v-html="item.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      careersArr: [
        {
          title: "Foreign trade salesman",
          loacl: "Beijing, China",
          peopleNum: 5,
          desc: `<div style=color:#2E3192 class='descTitle'>Responsibilities: </div> 
                <div class='desc'>Responsible for the technical design of products related to oil drilling tools.</div> 
                </br>
                <div style=color:#2E3192 class='descTitle'>Requirements:</div> 
                <div class='desc'>1.Bachelor degree or above, majoring in machining process, machinery manufacturing or other related majors.</div> 
                <div class='desc'>2.Familiar with mechanical design principles, skilled in using CAD, soilworks and other drawing software.</div> 
                <div class='desc'>3.Independent in technical support work, strong willingness to learn.</div> 
                <div class='desc'>4.Experience in oil drilling tools related industry is preferred.</div> 
                <div class='desc'>5.Proactive in work, with strong teamwork ability, responsibility and commitment.</div> 
                <div class='desc'>6.Physically fit and of good character.</div> 
                </br>
                <div style=color:#2E3192 class='descTitle'>Job benefits</div> 
                <div class='desc'>Five-year insurance, one gold, welfare awards.</div> `
        },
        {
          title: "Technical Engineer",
          loacl: "Beijing, China",
          peopleNum: 2,
          desc: `<div style=color:#2E3192 class='descTitle'>Responsibilities: </div> 
                <div class='desc'>1 Responsible for the development of foreign oilfield markets, petroleum equipment agents and petroleum equipment traders.</div> 
                <div class='desc'>2.Responsible for communication and negotiation with foreign users, facilitating cooperation orders, supervising and tracking the return of payment.</div> 
                <div class='desc'>3.Responsible for the implementation of foreign trade contracts and risk control in the trade process.</div> 
                <div class='desc'>4.Responsible for the development and maintenance of customers.</div> 
                </br>
                <div style=color:#2E3192 class='descTitle'>Job Requirements.</div>
                <div class='desc'>1.Excellent social skills in foreign language (fluent in reading, writing and listening).</div> 
                <div class='desc'>2.Bachelor degree or above in e-commerce, foreign language, marketing, international trade or other related majors (freshers accepted).</div> 
                <div class='desc'>3.Strong language communication skills, down-to-earth and good at learning.</div> 
                <div class='desc'>4.like sales, have a passion for sales. Dare to challenge themselves, strong anti-stress ability.</div> 
                <div class='desc'>Salary scale:Negotiable, fixed salary + performance salary.</div> 
                <div style=color:#2E3192 class='descTitle'>Job benefits</div> 
                <div class='desc'>Five-year insurance, one gold, welfare awards.</div> `
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.servcicesItemSmegma {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #17457c;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.joinUs {
  font-size: 80px;

  font-weight: 500;
  color: #ffffff;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topLogo {
  width: 100vw;
  height: 331px;
  background: #17457c;
  margin-bottom: 103px;
  position: relative;
}

.careersTitle {
  font-size: 40px;
  color: #2e3192;
  font-weight: bolder;
  width: 40%;
}

.iconWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.persionWrapper {
  display: flex;
  font-size: 36px;
  align-items: flex-end;
  margin-left: 50px;
  color: #2E3192;
}
.imgWrapper {
  width: 54px;
  box-sizing: border-box;
  height: 54px;
  padding: 4px 7px;
  display: inline-block;
  background: #ffb81d;
  margin-right: 20px;
  border-radius: 50%;
}
.persionIcon {
  width: 40px;
  height: 46px;
}

.localIcon {
  width: 40px;
  height: 46px;
}

.careersWrapper {
  margin: auto;
  width: 1445px;
  background: rgba(0, 0, 0, 0.17);
  padding: 40px 73px;
  margin-bottom: 67px;
  text-align: left;
  color: #000;
  font-size: 16px;

  font-weight: bolder;
  letter-spacing: 1.5px;
}
.careersDescription {
  font-size: 36px;
  margin-top: 34px;
  color: #2e3192;
  font-weight: bolder;
}
.careersDesc {
  font-size: 14px;
  line-height: 24px;
  ::v-deep .descTitle {
    font-size: 30px;
    color: #2e3192;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  ::v-deep .desc {
    font-size: 24px;
    line-height: 44px;
    color: #000;
  }
}

.careersTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.careersDesWrapper::before {
  width: 106px;
  height: 8px;
  background: #ffb81d;
  content: "";
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 10px;
}
</style>