<template>
  <div>
    <div class="topLogo">
      <div class="joinUs">CONTACT US</div>
      <!-- <div class="servcicesItemSmegma"></div> -->
      <img src="../img/contactUsLogo.jpg" alt />
    </div>
    <div class="titleWrapper">
      <h2 class="desTitle">We’re Here to Help</h2>
    </div>
    <div
      class="titleDes"
    >If you've got a question, we've got your answer. Fill out and submit our contact form below, and one of our representatives will follow up with you directly within a few business days.</div>
    <div class="talkWrapper">
      <div class="talkTitle">Hello.Let's talk</div>
      <el-form ref="ruleForm" :model="talkInput" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="talkInput.name" placeholder="Name*" class="inputName"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="talkInput.email" placeholder="E-mail*" class="inputEmail"></el-input>
        </el-form-item>
        <div>
          <div class="messageTitle">Message*</div>
          <el-form-item prop="message">
            <el-input type="textarea" class="messageWrapper" v-model="talkInput.message"></el-input>
          </el-form-item>
        </div>
        <div
          class="tipsWrapper"
        >Please provide specific location and contact information so we can expedite your request</div>
        <div style="text-align: left">
          <el-button class="talkBtn" @click="talkClick" :loading="bntLoading">Submit</el-button>
        </div>
      </el-form>
    </div>
    <div class="desWrapper">
      <h1 class="desWrapperTitle">Beijing WellDebon Technology Ltd.</h1>
      <div class="desContent">
        <div class="desContentFlex">
          <div class="desContentLeft">E-mail：</div>
          <div class="desContentRight">sales@welldebon.com</div>
        </div>
        <div class="desContentFlex">
          <div class="desContentLeft">tel：</div>
          <div class="desContentRight">+86-13582883885</div>
        </div>
        <div class="desContentFlex">
          <div class="desContentLeft">Website：</div>
          <div class="desContentRight">www.welldebon.com</div>
        </div>
        <div class="desContentFlex">
          <div class="desContentLeft">Address：</div>
          <div class="desContentRight">
            No. 18, Construction Road, Kaixuan Street, Liangshan District, Fangshan
            District, Beijing -
            D19596
          </div>
        </div>
      </div>
    </div>
    <div class="mapWrapper">
      <img src="../img/map.png" alt class="mapImg" />
    </div>
  </div>
</template>
  
<script>
import { submitMessage } from "@/api/index";
export default {
  data() {
    return {
      talkInput: {
        name: "",
        email: "",
        message: ""
      },
      rules: {
        name: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ],
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter the correct email address",
            trigger: ["blur", "change"]
          }
        ],
        message: [
          {
            required: true,
            message: "Please enter your message",
            trigger: "blur"
          }
        ]
      },
      bntLoading: false
    };
  },
  methods: {
    talkClick() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.bntLoading = true;
          submitMessage({ ...this.talkInput }).then(res => {
            if (res && res.code == 1) {
              this.$message({
                message: "Submitted successfully",
                type: "success"
              });
            } else {
              this.$message.error("Submission failed");
            }
            this.bntLoading = false;
          });
        } else {
        }
      });
    }
  }
};
</script>
  
<style lang="less" scoped>
.titleWrapper {
  font-size: 60px;
  color: #000;
  .desTitle {
    font-size: 1.44em;
  }
}
.titleDes {
  text-align: center;
  padding: 0 calc((100vw - 1500px) / 2);
  color: #000;
  font-size: 34px;
  font-weight: 400;
  color: #000000;
  line-height: 60px;
}
.topLogo {
  width: 100vw;
  height: 331px;
  background: #17457c;
  margin-bottom: 103px;
  position: relative;
}

.joinUs {
  font-size: 80px;

  font-weight: 500;
  color: #ffffff;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.servcicesItemSmegma {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #17457c;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.inputName,
.inputEmail {
  border: none;
  border-bottom: 1px solid #6b6b6b;
}

::v-deep .inputName .el-input__inner {
  background-color: #e6e6e6 !important;
  border: none;
  height: 70px;
  padding: 0;
  font-size: 22px;
}

::v-deep .inputName .el-input__inner::placeholder {
  margin-bottom: 20px;
  font-size: 22px;

  font-weight: normal;
  color: #808080;
}

::v-deep .inputEmail .el-input__inner {
  background-color: #e6e6e6 !important;
  border: none;
  padding: 0;
  height: 70px;
  font-size: 22px;
}

::v-deep .inputEmail .el-input__inner::placeholder {
  margin-bottom: 20px;
  font-size: 22px;

  font-weight: normal;
  color: #808080;
}

.desWrapper {
  width: 1442px;
  margin: auto;
  text-align: left;
  font-size: 19px;

  font-weight: normal;
  color: #5a5a5a;
  margin-top: 132px;
  margin-bottom: 65px;
}

.desContent {
  line-height: 40px;
  margin-top: 40px;
  font-size: 30px;
}
.desContent::before {
  width: 106px;
  height: 8px;
  background: #ffb81d;
  content: "";
  display: inline-block;
  margin-bottom: 5px;
}

.desWrapperTitle {
  font-size: 40px;

  font-weight: bolder;
  color: #2e3192;
}

.mapWrapper {
  margin: auto;
  width: 1442px;
  height: 665px;
  margin-bottom: 80px;
}

.mapImg {
  width: 100%;
  height: 100%;
}

.talkWrapper {
  margin: auto;
  width: 1444px;
  background: #e6e6e6;
  border-radius: 12px;
  margin-top: 93px;
  margin-bottom: 231px;
  padding: 40px 190px;
  box-sizing: border-box;
}

.talkTitle {
  font-size: 36px;

  font-weight: 400;
  color: #2e3192;
  text-align: left;
  margin-bottom: 55px;
}

.desContentLeft {
  display: inline-block;
  width: 5.5em;
}

.desContentRight {
  display: inline-block;
}

.messageWrapper {
  height: 280px;
}

::v-deep .messageWrapper .el-textarea__inner {
  height: 100%;
  font-size: 22px;
}

.messageTitle {
  font-size: 22px;

  font-weight: normal;
  color: #808080;
  margin-top: 48px;
  margin-bottom: 14px;
  text-align: left;
}

.talkBtn {
  background: #2e3192;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 70px;
}

::v-deep .el-button:focus {
  border-color: #404040;
  background-color: #404040;
  color: #ffffff;
}

::v-deep .el-button:hover {
  border-color: #404040;
  background-color: #404040;
  color: #ffffff;
}
.tipsWrapper {
  color: #000;
  font-size: 26px;
}
.desContentFlex {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
}
</style>