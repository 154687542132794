<template>
  <div class="queryEnterWrapper">
    <div
      v-for="(item, index) in queryArr"
      :key="index"
      class="queryEnter"
      :class="queryEnterAction == index ? 'queryEnterAction' : ''"
    >
      <div
        @mouseenter="selectHide"
        @click.stop="clickEnter($event, item, index)"
      >
        {{ item.title }}
      </div>
      <i
        @mouseenter="clickSelect(item)"
        v-if="item.type == 'select'"
        class="el-icon-caret-bottom"
      ></i>
    </div>
    <div
      class="selectList"
      @click="() => !showSelect"
      @mouseleave="selectHide"
      @mouseenter="servicesShow"
      v-show="showSelect"
      :class="selectTitle == 'SERVICES' ? 'servicesSelect' : 'productsSelect'"
    >
      <div
        class="selectItem"
        v-for="(utem, undex) in selectList"
        :key="undex"
        :class="activeRef == utem.ref ? 'active' : ''"
        @click="toSelect(utem)"
      >
        {{ utem.title }}
      </div>
    </div>
    <div
      v-show="productsListShow"
      class="productsSelectList"
      @click="() => !productsListShow"
      @mouseenter="productsShow"
      @mouseleave="selectHide"
    >
      <div
        v-for="(item, index) in productsList"
        :key="index"
        class="productsWrapper"
      >
        <div class="productsTitle">{{ item.name }}</div>
        <div>
          <div
            class="productsItem"
            v-for="(utem, undex) in item.show_product"
            :class="
              productsActiveType == item.id && productsActiveId == utem.id
                ? 'active'
                : ''
            "
            :key="undex"
            @click="toProductsSelect(item, utem)"
          >
            {{ utem.title }}
          </div>
        </div>
      </div>
    </div>
    <el-select v-model="language" class="langSelect">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getSubTitle } from "@/api";
export default {
  data() {
    return {
      language: "en",
      showSelect: false,
      selectList: [],
      productsList: [],
      productsListShow: false,
      selectTitle: "",
      activeRef: "",
      productsActiveType: "",
      productsActiveId: "",
      options: [
        {
          value: "en",
          label: "EN",
        },
      ],
    };
  },
  props: {
    queryEnterAction: {
      type: Number,
      defalut: 0,
    },
    queryArr: {
      type: Array,
      defalut: [],
    },
  },
  watch: {
    $route(to, from) {
      if (to.query && to.query.ref) {
        this.activeRef = to.query.ref;
      }
      if (to.query && to.query.type) {
        this.productsActiveType = to.query.type;
      }
      if (to.query && to.query.id) {
        this.productsActiveId = to.query.id;
      }
    },
  },
  mounted() {
    this.getSubTitleArr();
    this.productsActiveType = this.$route.query.type;
    this.productsActiveId = this.$route.query.id;
  },
  methods: {
    getSubTitleArr() {
      getSubTitle().then((res) => {
        this.productsList = res.data;

        let newArr = this.productsList[3];
        this.productsList[3] = this.productsList[6];
        this.productsList[6] = newArr;

        if (
          this.productsList[this.productsList.length - 1].show_product.length <
          this.productsList[this.productsList.length - 2].show_product.length
        ) {
          let newArr = this.productsList[this.productsList.length - 2];
          this.productsList[this.productsList.length - 2] =
            this.productsList[this.productsList.length - 1];
          this.productsList[this.productsList.length - 1] = newArr;
        }
      });
    },

    clickEnter($event, item, index) {
      $event.stopPropagation();
      this.$router.push(item.href);
      window.scrollTo(0, 0);
      this.showSelect = false;
      this.productsListShow = false;
      this.$emit("clickEnter", index);
    },
    clickSelect(item) {
      if (item.title == "SERVICES") {
        this.showSelect = true;
        this.productsListShow = false;
        this.selectList = item.selectList;
        this.selectTitle = item.title;
      } else if (item.title == "PRODUCTS") {
        this.productsListShow = true;
        this.showSelect = false;
      } else {
        this.productsListShow = false;
        this.showSelect = false;
      }
      this.$emit("clickSelect");
    },
    productsShow() {
      this.productsListShow = true;
      this.showSelect = false;
    },
    servicesShow() {
      this.showSelect = true;
      this.productsListShow = false;
      this.selectList = [
        { title: "SALES&RENTAL", path: "services?ref=sales", ref: "sales" },
        {
          title: "MAINTENANCE&INSPECTION",
          path: "services?ref=maintenance",
          ref: "maintenance",
        },
        {
          title: `DIRECTIONAL DRILLING SERVICES`,
          path: "services?ref=directional",
          ref: "directional",
        },
        {
          title: "FISHING SERVICES",
          path: "services?ref=fishing",
          ref: "fishing",
        },
      ];
      this.selectTitle = "SERVICES";
    },
    selectHide() {
      this.showSelect = false;
      this.productsListShow = false;
    },
    toProductsSelect(item, utem) {
      this.$router.replace(`/manufacturing?type=${item.id}&id=${utem.id}`);
      window.scrollTo(0, 0);
      location.reload();
      this.productsListShow = false;
    },
    toSelect(utem) {
      this.showSelect = false;
      const queryString = utem.path.split("?")[1];
      const params = new URLSearchParams(queryString);
      const ref = params.get("ref");
      if (this.$route.path == "/services" && utem.path.indexOf("?ref") >= 0) {
        this.$router.push({
          url: "/services",
          query: {
            ref: ref,
          },
        });
        this.$nextTick(() => {
          this.$router.push({
            url: "/services",

            query: {
              ref: ref,
            },
          });
        });
        if (ref == this.$route.query.ref) {
          this.$router.push({
            query: {},
          });
          this.$nextTick(() => {
            this.$router.push({
              url: "/services",

              query: {
                ref: ref,
              },
            });
          });
        }
      } else {
        this.$router.push(`/${utem.path}`);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.queryEnterAction = utem.indexId;
        if (utem.path.indexOf("?ref") >= 0) {
          this.$router.push({
            url: "/services",

            query: {
              ref: "",
            },
          });
          this.$nextTick(() => {
            this.$router.push({
              url: "/services",

              query: {
                ref: ref,
              },
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.queryEnterWrapper {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.queryEnter {
  // height: 46px;
  // line-height: 46px;
  display: flex;
  padding: 0px 20px;
  font-size: 22px;
  font-weight: 500;
  box-sizing: border-box;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.queryEnter:hover {
  color: #ffb81d;
}

.queryEnterAction {
  color: #ffb81d;
}
.langSelect {
  min-width: 60px;
  max-width: 100px;
  background: #2e3192;
  padding: 0;
  border: none;
  height: 20px;
  display: flex;
  align-items: center;
}

::v-deep .langSelect .el-input__inner {
  border: none;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  background: #2e3192;
  color: #fff;
}

::v-deep .langSelect .el-input {
  display: flex;
}

::v-deep .langSelect .el-input__suffix {
  position: static;
}
.langSelect::before {
  content: "";
  margin-left: 5px;
  margin-right: 28px;
  height: 30px;
  border: 1px solid #f0f0f0;
}
.selectList {
  position: absolute;
  color: #fff;
  background: #2e3192;
  text-align: left;
  font-size: 22px;
  top: 70px;
  left: 0px;
  opacity: 0.9;
}
.productsSelectList {
  position: fixed;
  color: #fff;
  background: #2e3192;
  text-align: left;
  font-size: 22px;
  left: 0;
  padding: 20px 30px 0 50px;
  width: 100vw;
  top: 135px;
  display: flex;
  box-sizing: border-box;
  overflow: scroll;
  flex-direction: column;
  flex-wrap: wrap;
  opacity: 0.9;
  justify-content: space-between;

  .productsWrapper {
    margin-bottom: 30px;
  }
  .productsWrapper:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  .productsTitle {
    font-weight: 600;
    color: #ffffff;
    border-bottom: 1px solid #fff;
    display: inline-block;
  }
  .productsItem {
    font-weight: 500;
    color: #ffffff;
  }
  .productsItem:hover {
    background: #1c1f69;

    color: #ffb81d;
  }
}
@media (min-width: 500px) {
  .productsSelectList {
    height: 600px;
  }
  .productsTitle {
    font-size: 25px;
  }
  .productsItem {
    font-size: 16px;
    line-height: 32px;
  }
}
@media (max-width: 500px) {
  .productsSelectList {
    height: 900px;
  }
  .productsTitle {
    font-size: 30px;
  }
  .productsItem {
    font-size: 22px;
    line-height: 40px;
  }
}
.servicesSelect {
  left: 350px;
  .selectItem {
    padding: 27px;
  }
  .selectItem:hover {
    background: #1c1f69;
    color: #ffb81d;
  }
}
.productsSelect {
  padding: 30px 60px;
}
.active {
  color: #ffb81d !important;
}
</style>