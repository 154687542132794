<template>
  <div class="home">
    <el-carousel trigger="click" class="carouselWrapper">
      <el-carousel-item v-for="(item, index) in headImg" :key="index">
        <img
          class="carouselImg"
          alt="加载中"
          data-src="../img/oilWall.png"
          :src="item.url || '../img/oilWall.png'"
          v-if="showCarousel"
        />
      </el-carousel-item>
    </el-carousel>
    <div>
      <div class="subTitle companyProfile" ref="profile">
        <h2 class="desTitle">COMPANY PROFILE</h2>
      </div>
      <div class="companyProfileDes">
        <div class="companyProfileDesLeft">
          <div class="companyProfileImgBg"></div>
          <img class="companyProfileImg" src="../img/companyImg.png" alt />
        </div>
        <div class="companyProfileDetails">
          Beijing Well Debon Technology Ltd. is a comprehensive oilfield service company
          mainly engaged in the research and development, manufacture of oil drilling tools and provision of various
          drilling services. Our main products include: high efficiency screw, rotary guide screw, all-metal screw,
          downhole drilling shocker, special multi-purpose drilling tools, fishingtools, etc. Our main customers are
          PetroChina, Sinopec, CNOOC and other companies in the Middle East and Southeast Asia, and we also provide
          directional well, drilling speed and salvage services for PetroChina, Sinopec and CNOOC.Well Debon adheres to
          the business philosophy of "leading technology, service first, honesty based, and sincere cooperation",
          providing customers with high-quality products and services. We are willing to cooperate with our domestic and
          foreign counterparts, leverage our respective advantages, and develop and grow together.
        </div>
      </div>
    </div>
    <div>
      <div class="servcicesWrapper">
        <div
          v-for="(item, index) in servicesArr"
          :key="index"
          class="servcicesItem"
          @click="servcicesItemMore(item)"
        >
          <div class="servcicesItemSmegma"></div>

          <img :src="item.img" alt />
          <div class="servcicesItemTitle">
            <div class="servcicesItemBig">{{ item.title1 }}</div>
            <div class="servcicesItemBig" v-if="item.title2">&</div>
            <div class="servcicesItemBig">{{ item.title2 }}</div>
          </div>
        </div>
      </div>
      <div class="tipsWrapper">
        <div class="tipsLeft">
          <div class="tipsLeftTitle">Hi-Teach drilling tools cover your requirement.</div>
          <div class="tipsShowArr">
            <div>Long life</div>
            <div>High temperature</div>
            <div>Save cost</div>
            <div>One trip drilling</div>
          </div>
        </div>
        <div class="learnMore" @click="toLearnMore">LEARN MORE</div>
      </div>
    </div>

    <div class="cooperativeWrapperDiv">
      <div class="subTitle cooperativeTitle" ref="culture">
        <h2 class="desTitle">COOPERATIVE PARTNER</h2>
      </div>

      <div class="cooperativeWrapper">
        <div v-for="(item, index) in cooperativeArr" :key="item.id" class="cooperativeImgWrapper">
          <img :src="item.img" alt class="cooperativeImg" />
        </div>
      </div>
    </div>

    <div>
      <div class="subTitle" ref="newsCenter">
        <h2 class="desTitle">NEWS CENTER</h2>
      </div>
      <div class="bottomDesWrapper">
        <div v-for="(item,index) in newsCenterArr" :key="index" class="newsCenterItem" @click="toNewsDetails(item)">
          <img class="newsCenterImg" :src="item.avatar" alt />
          <div class="newCenterDes">
            <div class="newsCenterTitle">{{ item.title }}</div>
            <div class="newsCenterBottom">
              <div>corporate news</div>
              <div>{{ `${new Date(item.updatetime *1000).getDay()}/${new Date(item.updatetime*1000).getMonth() + 1}/${new Date(item.updatetime*1000).getFullYear()}` }}</div>
            </div>
          </div>
          <div class="newCenterIntroduce">
            <div class="title">{{ item.title }}</div>
            <div class="content" v-html="item.detail"></div>
            <div class="foot">
              <div>corporate news</div>
              <div>{{ `${new Date(item.updatetime *1000).getDay()}/${new Date(item.updatetime*1000).getMonth() + 1}/${new Date(item.updatetime*1000).getFullYear()}` }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="welldebonImgWrapper">
        <!-- <img src="../img/welldebonBack.png" alt=""> -->
        <img src="../img/opacity.png" alt class="welldebonImgMain" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import serviceManufacturing from "../img/serviceManufacturing.png";
import serviceSales from "../img/serviceSales.png";
import serviceLeasing from "../img/serviceLeasing.png";
import fishing from "../img/fishing.png";
import sinopec from "../img/sinopec.png";

import img1 from "@/img/cooperative/1.png";
import img2 from "@/img/cooperative/2.png";
import img3 from "@/img/cooperative/3.png";
import img4 from "@/img/cooperative/4.png";
import img5 from "@/img/cooperative/5.png";
import img6 from "@/img/cooperative/6.png";
import img7 from "@/img/cooperative/7.png";
import img8 from "@/img/cooperative/8.png";
import img9 from "@/img/cooperative/9.png";
import img10 from "@/img/cooperative/10.png";
import img11 from "@/img/cooperative/11.png";
import img12 from "@/img/cooperative/12.png";
import img13 from "@/img/cooperative/13.png";
import img15 from "@/img/cooperative/15.png";

import { news } from "@/api/index";

export default {
  name: "HomeView",
  data() {
    return {
      servicesArr: [
        {
          title1: "SALES",
          title2: "RENTAL",
          img: serviceManufacturing,
          path: "services?ref=sales"
        },
        {
          title1: "MAINTENANCE",
          title2: "INSPECTION",
          img: serviceSales,
          path: "services?ref=maintenance"
        },
        {
          title1: "DIRECTIONAL",
          title2: "DRILLING SERVICES",
          img: serviceLeasing,
          path: "services?ref=directional"
        },
        {
          title1: "FISHING SERVICES",
          img: fishing,
          path: "services?ref=fishing"
        }
      ],
      newsCenterArr: [],
      cooperativeArr: [
        {
          id: 1,
          img: img1
        },
        {
          id: 2,
          img: img2
        },
        {
          id: 3,
          img: img3
        },
        {
          id: 4,
          img: img4
        },
        {
          id: 5,
          img: img5
        },
        {
          id: 6,
          img: img6
        },
        {
          id: 7,
          img: img7
        },
        {
          id: 8,
          img: img8
        },
        {
          id: 9,
          img: img9
        },
        {
          id: 10,
          img: img10
        },
        {
          id: 11,
          img: img11
        },
        {
          id: 12,
          img: img12
        },
        {
          id: 13,
          img: img13
        },
        {
          id: 14,
          img: sinopec
        },
        {
          id: 15,
          img: img15
        }
      ],
      currentScroll: 0,
      headImg: [
        {
          filename: "默认图片",
          thumb_style: "",
          url: require("../img/oilWall.png")
        }
      ],
      showCarousel: true
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      if (to.path == from.path && to.query && to.query.ref) {
        window.scrollTo({
          top:
            this.$refs[this.$route.query.ref].offsetTop -
            this.$refs[this.$route.query.ref].offsetHeight,
          behavior: "smooth"
        });
      }
    }
  },
  created() {
    this.loadImges();
    this.getnewsList();
  },
  mounted() {
    if (this.$route.query && this.$route.query.ref) {
      window.scrollTo({
        top:
          this.$refs[this.$route.query.ref].offsetTop -
          this.$refs[this.$route.query.ref].offsetHeight,
        behavior: "smooth"
      });
    }
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods: {
    servcicesItemMore(utem) {
      const queryString = utem.path.split("?")[1];
      const params = new URLSearchParams(queryString);
      const ref = params.get("ref");
      if (this.$route.path == "/services" && utem.path.indexOf("?ref") >= 0) {
        this.$router.push({
          url: "/services",
          query: {
            ref: ref
          }
        });
        this.$nextTick(() => {
          this.$router.push({
            url: "/services",

            query: {
              ref: ref
            }
          });
        });
        if (ref == this.$route.query.ref) {
          this.$router.push({
            query: {}
          });
          this.$nextTick(() => {
            this.$router.push({
              url: "/services",

              query: {
                ref: ref
              }
            });
          });
        }
      } else {
        this.$router.push(`/${utem.path}`);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.queryEnterAction = utem.indexId;
        if (utem.path.indexOf("?ref") >= 0) {
          this.$router.push({
            url: "/services",

            query: {
              ref: ""
            }
          });
          this.$nextTick(() => {
            this.$router.push({
              url: "/services",

              query: {
                ref: ref
              }
            });
          });
        }
      }
    },
    // async getHeadingImg() {
    //   headingImg().then(res => {
    //     if (res && res.code == 1) {
    //       this.headImg = JSON.parse(JSON.stringify(res.data))
    //     }
    //   })
    // },
    getnewsList() {
      news({ pageSize: 4 }).then(res => {
        if (res && res.code == 1) {
          let newsCenterArrs = [];
          newsCenterArrs = res.data.list.map((item, index) => {
            return {
              ...item
              // id: index,
            };
          });
          this.newsCenterArr = JSON.parse(JSON.stringify(newsCenterArrs));
        }
      });
    },
    loadImges() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(
            "https://admin.welldebon.com/api/content/headimg"
          ); // 发起接口请求获取图片数据
          const data = await response.json();
          let imgArr = data.data;
          const promises = imgArr.map((item, index) => {
            return new Promise(resolve => {
              const img = new Image();
              img.onload = resolve;
              img.src = item.url;
            });
          });

          await Promise.all(promises);
          this.headImg = imgArr;
          this.showCarousel = false;
          this.$nextTick(() => {
            this.showCarousel = true;
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    toLearnMore() {
      window.scrollTo(0, 0);
      this.$router.push("/products?type=3");
    },
    // replaceImage(event, item) {
    //   const img = event.target;
    //   img.src = item.url;
    // },
    toNewsDetails(item){
      window.scrollTo(0, 0);
      this.$router.push(`/newsCenterDetails/${item.id}`)
    }
  }
};
</script>
<style>
img {
  width: 100%;
  height: 100%;
}
</style>

<style lang="less" scoped>
.carouselWrapper {
  margin-bottom: 153px;
}
::v-deep .carouselWrapper .el-carousel__container {
  height: 900px;
}
// ::v-deep .carouselWrapper .el-carousel__container {
//   background-image: url('../img/oilWall.png');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 100% 100%;
//   background-origin: content-box;
//   background-clip: border-box;
// }

.carouselImg {
  height: 100%;
  width: 100%;
  background-image: url("../img/oilWall.png");

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-origin: content-box;
  background-clip: border-box;
}

.subTitle {
  font-size: 56px;
  font-family: SimHei;
  font-weight: 500;
  color: #000;
  line-height: 60px;
  padding-bottom: 45px;
  position: relative;
  .desTitle{
    font-size: 1.44em;
  }
}

.bottomDesWrapper {
  padding: 0 227px;
  display: flex;
  justify-content: space-between;
  margin-top: 130px;
}

.bottomDes {
  font-size: 48px;
  font-weight: 500;
  color: #464646;
  margin-top: 42px;
}

.bottomDes:nth-of-type(1) {
  margin-top: 0;
}

.welldebonImgWrapper {
  position: relative;
  width: 1038px;
  height: 106px;
  margin: auto;
  margin-top: 138px;

  margin-bottom: 222px;
  transition: all 0.5s;
  overflow: hidden;
}

.welldebonImgWrapper:hover {
  .welldebonImgBack {
    opacity: 1;
  }
}

.welldebonImgMain,
.welldebonImgBack {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s;
}

.welldebonImgBack {
  z-index: 1;
  opacity: 0.5;

  // transform: translate(-100%, 0%);
}

.welldebonImgMain {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url("@/img/welldebonBack.png");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  // background-size: 100% 100%;
  // background-origin: content-box;
  background-clip: border-box;
  position: absolute;
  background-attachment: fixed;
  background-size: cover;
  // border: none;
  clip-path: inset(2px 2px 2px 2px);
  -webkit-clip-path: inset(2px 2px 2px 2px);
}

.companyProfileDes {
  margin-bottom: 164px;
}

.companyProfileDes {
  margin-top: 164px;
  margin-bottom: 188px;
  font-size: 18px;

  font-weight: normal;
  color: #000000;
  padding: 0 213px 0 240px;
  display: flex;
}

.companyProfileDetails {
  flex: 1;
  line-height: 1.5em;
  text-align: justify;
  font-size: 22px;
}

.servcicesWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 127px;
}

.servcicesItem {
  width: 25%;
  height: 793px;
  // background: rgba(0, 0, 0, 0.4);
  position: relative;
  // opacity: 0.4;
  background: #000000;
  cursor: pointer;
  overflow: hidden;
  .servcicesItemSmegma {
    top: -50%;
    left: -50%;
  }
}

.servcicesItem:hover {
  box-shadow: 0px 3px 8px 8px rgba(222, 221, 225, 0.3);

  .servcicesItemSmegma {
    top: -155%;
    left: -155%;
  }
}

.servcicesItemSmegma {
  width: 200%;
  height: 200%;
  position: absolute;
  top: -200%;
  left: -200%;
  overflow: hidden;
  background: #000000;
  transform: rotate(45deg);
  z-index: 2;
  opacity: 0.4;
  transition: all 0.5s;
}

.servcicesItemTitle {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px;

  font-weight: 500;
  color: #ffffff;
  z-index: 3;
}

.servcicesItemBig {
  font-size: 36px;
}

.servcicesItemMore {
  margin: auto;
  width: max-content;
  margin-top: 44px;
  font-size: 18px;

  font-weight: normal;
  color: #ffffff;
}

.servcicesItemMore::after {
  content: "";
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: #ffffff;
  display: block;
}

.cooperativeWrapper {
  display: grid;
  grid-template-columns:
    calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5)
    calc(100% / 5);
  margin-top: 130px;
  padding: 0 170px;
  padding-bottom: 203px;
  margin-bottom: 100px;
}

.companyProfileImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 756px;
  height: 504px;
  z-index: 1;
}
.companyProfileDesLeft {
  position: relative;
  width: 756px;
  height: 504px;
  margin-right: 70px;
}
.companyProfileImgBg {
  z-index: 0;
  top: -40px;
  left: -40px;
  position: absolute;
  width: 756px;
  height: 504px;
  background: #2e3192;
}

.cooperativeImg {
  height: 80px;
  width: auto;
  max-width: 100%;
}

.cooperativeImgWrapper {
  height: 176px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.tipsWrapper {
  height: 245px;
  display: flex;
  padding: 40px 140px;
  background: orange;
  color: #fff;
  justify-content: space-between;
  align-items: center;
}
.tipsLeft {
  width: 1148px;
}
.tipsLeftTitle {
  text-align: justify !important;
  letter-spacing: 2px;
  font-size: 50px;
}
.tipsShowArr {
  margin-top: 35px;
  display: flex;
  font-size: 36px;
  justify-content: space-between;
}
.learnMore {
  width: 290px;
  height: 73px;
  border: 3px solid #fff;
  border-radius: 10px;
  font-size: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.cooperativeWrapperDiv {
  margin-top: 65px;
  background-image: url("@/img/cooperative/bg.png");
  background-size: 100% 100%;
}
.newsCenterItem {
  width: 338px;
  height: 390px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.newsCenterItem:hover {
  .newCenterIntroduce {
    top: 0;
    left: 0;
  }
}
.newsCenterImg {
  width: 338px;
  display: block;
  height: 239px;
}
.newCenterDes {
  padding: 25px;
  box-sizing: border-box;
  height: 150px;
  background: rgba(0, 0, 0, 0.2);
}
.newsCenterTitle {
  height: 90%;
  font-size: 16px;
  color: #000;
  text-align: left;
}
.newsCenterBottom {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000;
}
.newCenterIntroduce {
  padding: 30px;
  transition: all 0.5s;
  position: absolute;
  box-sizing: border-box;
  background: orange;
  color: #2e3192;
  height: 100%;
  width: 100%;
  top: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .title {
    font-size: 24px;
    margin-bottom: 37px;
  }
  .content::before {
    content: "";
    width: 247px;
    height: 3px;
    background: #2e3192;
    display: block;
    margin-bottom: 20px;
  }
  .content {
    font-size: 16px;
    text-align: justify;
    flex: 1;
  }
  .foot {
    font-size: 14px;
    color: #2e3192;
    display: flex;
    justify-content: space-between;
  }
}
.cooperativeTitle {
  padding-top: 70px;
  padding-bottom: 0;
}
</style>
