<template>
  <div id="app">
    <el-backtop></el-backtop>

    <el-container>
      <el-header class="containerHeader">
        <div class="headerLogoWrapper" @mouseleave="selectHide">
          <img class="headerLogo" src="./img/logo.png" @click="logoClick" />
        </div>
        <div class="enterSearchWrapper" @mouseleave="selectHide">
          <div class="searchWrapper" @click="changeShowSearch">
            <div>sales@welldebon.com</div>
            <i class="el-icon-search"></i>
          </div>
          <queryEnter
            :queryEnterAction="queryEnterAction"
            @clickEnter="clickEnter"
            @clickSelect="clickSelect"
            :queryArr="queryArr"
            ref="queryEnter"
          ></queryEnter>
          <div class="searchBox" :class="{showSearchBox:showSearch}">
            <div class="searchInputWrapper">
              <el-input placeholder="Please enter the content" v-model="input3">
                <el-button slot="append" icon="el-icon-search" @click="homeSearch"></el-button>
              </el-input>
              <div class="searchResultWrapper" v-if="searchResult.length>0">
                <div
                  v-for="(item,index) in searchResult"
                  :key="index"
                  @click="searchResultClick(item)"
                  class="searchResultItem"
                >{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </el-main>
      <el-footer class="containerFooter">
        <footComponents @leftClick="leftClick"></footComponents>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
import footComponents from "./components/footComponents.vue";
import queryEnter from "@/components/queryEnter";
import { homeSearchApi } from "@/api";
export default {
  data() {
    return {
      queryEnterAction: 0,
      queryArr: [
        {
          title: "ABOUT US",
          href: "/",
          children: []
        },
        {
          title: "PRODUCTS",
          href: "/products?type=3",
          type: "select",
          selectList: [
            { title: "SALES&RENTAL", path: "/services?ref=sales" },
            {
              title: "MAINTENANCE&INSPECTION",
              path: "/services?ref=maintenance"
            },
            {
              title: `DIRECTIONAL DRILLING SERVICES`,
              path: "/services?ref=directional"
            },
            { title: "FISHING SERVICES", path: "/services?ref=fishing" }
          ],
          children: ["/products", "/manufacturing", "/details"]
        },
        {
          title: "SERVICES",
          href: "/services",
          type: "select",
          selectList: [
            { title: "SALES&RENTAL", path: "services?ref=sales", ref: "sales" },
            {
              title: "MAINTENANCE&INSPECTION",
              path: "services?ref=maintenance",
              ref: "maintenance"
            },
            {
              title: `DIRECTIONAL DRILLING SERVICES`,
              path: "services?ref=directional",
              ref: "directional"
            },
            {
              title: "FISHING SERVICES",
              path: "services?ref=fishing",
              ref: "fishing"
            }
          ],
          children: ["/services"]
        },
        {
          title: "NEWS CENTER",
          href: "/newsCenter",
          children: ["/newsCenterDetails"]
        },
        {
          title: "CAREERS",
          href: "/careers",
          children: []
        },
        {
          title: "CONTACT US",
          href: "/contactUs",
          children: []
        }
      ],
      input3: "",
      showSearch: false,
      searchResult: []
    };
  },
  created() {
    for (let i = 0; i < this.queryArr.length; i++) {
      if (this.$route.path.indexOf(this.queryArr[i].href) >= 0) {
        this.queryEnterAction = i;
      } else {
        if (this.queryArr[i].children && this.queryArr[i].children.length > 0) {
          for (let j = 0; j < this.queryArr[i].children.length; j++) {
            if (this.$route.path.indexOf(this.queryArr[i].children[j]) >= 0) {
              this.queryEnterAction = i;
            }
          }
        }
      }
    }
  },
  mounted() {
    document.cookie = null;
  },
  watch: {
    $route(to, from) {
      for (let i = 0; i < this.queryArr.length; i++) {
        if (this.$route.path.indexOf(this.queryArr[i].href) >= 0) {
          this.queryEnterAction = i;
        } else {
          if (
            this.queryArr[i].children &&
            this.queryArr[i].children.length > 0
          ) {
            for (let j = 0; j < this.queryArr[i].children.length; j++) {
              if (this.$route.path.indexOf(this.queryArr[i].children[j]) >= 0) {
                this.queryEnterAction = i;
              }
            }
          }
        }
      }
      if (
        to.path.indexOf("/details") >= 0 ||
        to.path.indexOf("/products") >= 0 ||
        to.path.indexOf("/manufacturing") >= 0
      ) {
        this.clickEnter(1);
      }
    }
  },
  components: {
    queryEnter,
    footComponents
  },
  methods: {
    selectHide() {
      this.$refs.queryEnter.showSelect = false;
      this.$refs.queryEnter.productsListShow = false;
    },
    async leftClick(item, path) {
      const queryString = item.path.split("?")[1];
      const params = new URLSearchParams(queryString);
      const ref = params.get("ref");
      if (this.$route.path == path && item.path.indexOf("?ref") >= 0) {
        this.$router.push({
          url: path,
          query: {
            ref: ref
          }
        });
        this.$nextTick(() => {
          this.$router.push({
            url: path,
            query: {
              ref: ref
            }
          });
        });
        if (ref == this.$route.query.ref) {
          this.$router.push({
            url: path,
            query: {}
          });
          this.$nextTick(() => {
            this.$router.push({
              url: path,
              query: {
                ref: ref
              }
            });
          });
        }
      } else {
        if(this.$route.path == item.path.split("?")[0] && this.$route.query.type != item.type){
          this.$router.push(`${item.path}`);
          await this.$router.go(0)
        }else{
          this.$router.push(`${item.path}`);
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.queryEnterAction = item.indexId;
        if (item.path.indexOf("?ref") >= 0) {
          this.$router.push({
            query: {
              ref: ""
            }
          });
          this.$nextTick(() => {
            this.$router.push({
              query: {
                ref: ref
              }
            });
          });
        }
      }
    },
    clickEnter(index) {
      this.queryEnterAction = index;
    },
    clickSelect() {
      this.showSearch = false;
      this.input3 = "";
      this.searchResult = [];
    },
    changeShowSearch() {
      this.$refs.queryEnter.showSelect = false;
      this.$refs.queryEnter.productsListShow = false;
      this.showSearch = !this.showSearch;
      if (!this.showSearch) {
        this.input3 = "";
        this.searchResult = [];
      }
    },
    logoClick() {
      this.queryEnterAction = 0;
      if (this.$route.path.indexOf("/home") < 0) {
        this.$router.push("/home");

        window.scrollTo({
          top: 0
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    },
    homeSearch() {
      console.log(this.$refs.queryEnter);
      this.$refs.queryEnter.showSelect = false;
      this.$refs.queryEnter.productsListShow = false;
      homeSearchApi({ key: this.input3 }).then(res => {
        this.searchResult = res.data;
      });
    },
    searchResultClick(item) {
      this.$refs.queryEnter.showSelect = false;
      this.$refs.queryEnter.productsListShow = false;
      let url;
      if (item.cateid) url = `/manufacturing?id=${item.id}&type=${item.cateid}`;
      else url = `/manufacturing?id=${item.id}`;
      this.$router.push(url);
      location.reload();
      window.scrollTo(0, 0);
      this.showSearch = false;
      this.input3 = "";
      this.searchResult = [];
    }
  }
};
</script>

<style lang="less">
@media (max-width: 500px) {
  html{
    font-size: medium !important;
  }
  body{
    font-size: medium !important;
  }
}
* {
  font-family: "Helvetica";
}

::-webkit-scrollbar {
  width: 0;
}

.el-backtop {
  background-color: rgba(2, 50, 141, 1) !important;
  color: #fff !important;
}

body {
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 137px !important;
}

.el-container {
  min-height: 100vh;
}

.el-header,
.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
}
::deep(.el-header) {
  padding: 0;
}
.el-main {
  background-color: #fff;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  overflow-x: hidden !important;
}
</style>


<style lang="less" scoped>
.containerHeader {
  padding: 0 !important;
  display: flex;
  height: 138px !important;
  position: fixed;
  width: 100%;
  top: 0;
  background: #2e3192;
  color: #fff;
  z-index: 1000;
  justify-content: center;
}

.headerLogo {
  margin-right: 250px;
  width: 300px;
  height: 30px;
  cursor: pointer;
}

.headerLogoWrapper {
  display: flex;
  align-items: center;
}

.containerFooter {
  width: 100%;
  height: 454px !important;
  background-color: #2e3192;
  padding: 71px 150px 85px 150px;
  box-sizing: border-box;
}
.searchWrapper {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  font-size: 30px;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}
.enterSearchWrapper {
  padding: 30px 0;
  box-sizing: border-box;
  position: relative;
}
.el-icon-search {
  margin-left: 65px;
  margin-right: 20px;
}
.searchBox {
  background: #2e3192;
  top: 50px;
  width: 100vw;
  left: -705px;
  position: absolute;
  transition: all 0.2s;
  opacity: 0;
  z-index: -999999;

  .searchInputWrapper {
    ::v-deep .el-input__inner {
      border: none;
      background: #2e3192;
      color: #fff;

      
    }
  }
}
.showSearchBox {
  transition: all 0.2s;
  opacity: 1;
  top: 138px;
  z-index: 1;
}
.searchResultWrapper {
  background: #2e3192;
  text-align: left;
  padding: 20px;
  height: 200px;
  overflow: scroll;
  opacity: 0.9;
  color: #fff;
  .searchResultItem {
    font-size: 20px;
    padding: 10px 0;
    cursor: pointer;
  }
  .searchResultItem:hover {
    color: #ffb81d;
  }
}
</style>