<template>
  <div class="wrapper">
    <div class="popContactWrapper">
      <div class="popPosition">
        <div class="topWrapper" :class="{ showPopContact: showPop }">
          <div class="title">Hello.Let's talk</div>
          <el-form ref="ruleForm" :model="talkInput" :rules="rules">
            <el-form-item prop="name">
              <el-input
                v-model="talkInput.name"
                placeholder="Name*"
                class="inputName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input
                v-model="talkInput.email"
                placeholder="E-mail*"
                class="inputEmail"
              ></el-input>
            </el-form-item>
            <div>
              <div class="messageTitle">Message*</div>
              <el-form-item prop="message">
                <el-input
                  type="textarea"
                  class="messageWrapper"
                  v-model="talkInput.message"
                ></el-input>
              </el-form-item>
            </div>
            <div class="tipsWrapper">
              Please provide specific location and contact information so we can
              expedite your request
            </div>
            <div style="text-align: left">
              <el-button
                class="talkBtn"
                @click="talkClick"
                :loading="bntLoading"
                >Submit</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
      <div class="footWrapper" @click="showPopContact">
        Have a question?Contanct up.
      </div>

      <!-- <div class="popButton" >
        <i class="popIcon" :class="!showPop?'el-icon-arrow-left':'el-icon-arrow-right'"></i>
      </div>-->
    </div>
  </div>
</template>

<script>
import { submitMessage } from "@/api/index";

export default {
  data() {
    return {
      showPop: false,
      talkInput: {
        name: "",
        email: "",
        message: "",
      },
      rules: {
        name: [
          { required: true, message: "Please enter a name", trigger: "blur" },
        ],
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address",
            trigger: ["blur", "change"],
          },
        ],
        message: [
          {
            required: true,
            message: "Please enter your message",
            trigger: "blur",
          },
        ],
      },
      bntLoading: false,
    };
  },
  methods: {
    talkClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.bntLoading = true;
          submitMessage({ ...this.talkInput }).then((res) => {
            if (res && res.code == 1) {
              this.$message({
                message: "Submitted successfully",
                type: "success",
              });
            } else {
              this.$message.error("Submission failed");
            }
            this.bntLoading = false;
          });
        } else {
        }
      });
    },
    showPopContact() {
      this.showPop = !this.showPop;
    },
  },
};
</script>

<style lang="less" scoped>
.popContactWrapper {
  transition: all 0.5s;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  padding: 40px 0 0 0;
  margin: auto;
  .popPosition {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .topWrapper {
    position: absolute;
    left: 0;
    height: 0;
    overflow: hidden;
    z-index: 999999998;
    background: #e6e6e6;
    border-radius: 12px 12px 0 0;
    padding-left: 35px;
    box-sizing: border-box;
    padding-right: 35px;
    transition: all 0.3s;
  }
  .popButton {
    position: absolute;
    bottom: 20%;
    left: -50px;
    background: #2e3192;
    width: 50px;
    padding: 20px 0;
    cursor: pointer;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
@media (max-width: 500px) {
  .popContactWrapper {
    bottom: 454px;
  }
}

.title {
  font-size: 36px;

  font-weight: 400;
  color: #2e3192;
  text-align: left;
  margin-bottom: 55px;
}
.inputName,
.inputEmail {
  border: none;
  border-bottom: 1px solid #6b6b6b;
}

::v-deep .inputName .el-input__inner {
  background-color: #e6e6e6 !important;
  border: none;
  height: 50px;
  padding: 0;
  font-size: 18px;
}

::v-deep .inputName .el-input__inner::placeholder {
  margin-bottom: 20px;
  font-size: 18px;

  font-weight: normal;
  color: #808080;
}

::v-deep .inputEmail .el-input__inner {
  background-color: #e6e6e6 !important;
  border: none;
  padding: 0;
  height: 70px;
  font-size: 18px;
}

::v-deep .inputEmail .el-input__inner::placeholder {
  margin-bottom: 20px;
  font-size: 18px;

  font-weight: normal;
  color: #808080;
}
.talkBtn {
  background: #2e3192;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 20px;
}

::v-deep .el-button:focus {
  border-color: #2e3192;
  background-color: #2e3192;
  color: #ffb81d;
}
::v-deep .el-form-item__error {
  font-size: 16px;
}
::v-deep .el-button:hover {
  border-color: #2e3192;
  background-color: #2e3192;
  color: #ffb81d;
}
.messageWrapper {
  height: 100px;
  border-radius: 5px;
}

::v-deep .messageWrapper .el-textarea__inner {
  height: 100%;
  font-size: 18px;
}
.messageTitle {
  font-size: 22px;

  font-weight: normal;
  color: #808080;
  margin-top: 48px;
  margin-bottom: 14px;
  text-align: left;
}
.tipsWrapper {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  text-align: left;
  line-height: 21px;
}
.footWrapper {
  width: 100%;
  height: 120px;
  text-align: center;
  font-size: 24px;
  background: #2e3192;
  line-height: 120px;
  z-index: 999999999;
  position: relative;
  opacity: 0.9;
  cursor: pointer;
}
.wrapper {
  position: relative;
}
.showPopContact {
  height: auto !important;
  opacity: 1 !important;
  bottom: 0;
  overflow: auto !important;
  padding: 0.5rem 0 0.375rem 0;
}
.popIcon {
  font-size: 40px;
}
</style>